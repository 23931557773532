<template>
  <div class="factory-distribution">
    <div id="province-map" class="map-chart"></div>
    <div class="right-div">
      <div class="top-nav">
        <div
          :class="checkedOne == 0 ? 'nav-btn checked-nav' : 'nav-btn'"
          @click="chooseOne(0)"
        >
          <span>产业分布</span>
        </div>
        <div
          :class="checkedOne == 1 ? 'nav-btn1 checked-nav' : 'nav-btn1'"
          @click="chooseOne(1)"
        >
          <span>区域分布</span>
        </div>
      </div>
      <div class="bottom-ring">
        <div id="ring-echarts" v-if="checkedOne == 0"></div>
        <div id="pie-echarts" v-if="checkedOne == 1"></div>
        <!-- <div id="ring-echarts"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import shanghaiMap from "../assets/map/json/province/shanghai.json";
export default {
  name: "FactoryDistribution",
  props: {
    msg: String,
  },
  data() {
    return {
      listArr: [],
      myChart1: null,
      myChart2: null,
      myChart3: null,
      echartData1: [
        { value: 8, name: "临港新片区", proportion: 10 },
        { value: 1, name: "普陀区", proportion: 10 },
        { value: 1, name: "杨浦区", proportion: 10 },
        { value: 25, name: "浦东新区", proportion: 10 },
        { value: 23, name: "闵行区", proportion: 10 },
        { value: 4, name: "宝山区", proportion: 10 },
        { value: 15, name: "嘉定区", proportion: 10 },
        { value: 4, name: "金山区", proportion: 10 },
        { value: 12, name: "松江区", proportion: 10 },
        { value: 4, name: "青浦区", proportion: 10 },
        { value: 6, name: "奉贤区", proportion: 10 },
      ],
      color2: [
        [
          {
            offset: 0,
            color: "rgba(175, 215, 170, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(175, 215, 170, 0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(204, 51, 255, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(204, 51, 255, 0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(51, 51, 255, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(51, 51, 255, 0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(246, 189, 22, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(246, 189, 22, 0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(232, 104, 74, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(232, 104, 74, 0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(110, 65, 228, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(110, 65, 228, 1)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(228, 56, 116, 0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(228, 56, 116, 1)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(102, 0, 51,0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(102, 0, 51,0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(255, 204, 102,0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(255, 204, 102,0.85)", // 100% 处的颜色
          },
        ],
        [
          {
            offset: 0,
            color: "rgba(51, 204, 51,0.2)", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "rgba(51, 204, 51,0.85)", // 100% 处的颜色
          },
        ],
      ],
      checkedOne: 0,
      imgShowMap: [],
      mapCenter: [],
      allIndustry: [],
      imgIdentification: [],
      // 下面是工厂地图
      option1: {
        visualMap: {
          show: false,
          inRange: {
            color: ["lightskyblue"],
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
          backgroundColor: "rgba(50,50,50,0.7)",
          textStyle: {
            color: "#fff", // 文字颜色为白色
          },
          formatter: function (params) {
            var factoryInfo = params.data; // 你的工厂信息数据结构
            // console.log(`params=`, params)
            if (factoryInfo.simple_name) {
              return "工厂名：" + factoryInfo.simple_name; // 你的工厂信息显示方式
            } else {
              return false;
            }
          },
        },
        series: [
          {
            type: "map",
            map: "shanghai",
            roam: true,
            zoom: 1,
            layoutCenter: ["30%", "51%"],
            layoutSize: "100%",
            label: {
              emphasis: {
                show: true,
                itemStyle: {
                  areaColor: "#0b2065",
                },
              },
              normal: {
                textStyle: {
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#fff",
                },
              },
            },
            itemStyle: {
              normal: { label: { show: true } },
              emphasis: {
                label: {
                  color: "#fff",
                },
                // 地图区域的高亮颜色
                areaColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#0d58bd", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#00208f", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            select: {
              label: {
                show: true,
                color: "#fff",
                rotate: true,
              },
              itemStyle: {
                areaColor: "#0b2065",
                // areaColor: null,
              },
            },
            markPoint: {
              symbol: "image://" + require("../assets/imgs/factory.png"),
              symbolSize: [28, 49],
              label: {
                fontSize: 16,
                width: 150,
                fontFamily: "PingFangSC-Medium, PingFang SC",
                fontWeight: 500,
                overflow: "truncate",
                position: "top",
                color: "#fff",
              },
              emphasis: {
                label: {
                  show: true,
                  overflow: "none",
                },
              },
              data: [],
            },
            data: [],
          },
        ],
      },
      option2: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return params.name + "工厂数量：" + params.value + "家";
          },
        },
        legend: {
          bottom: "5%",
          left: "center",
          icon: "circle",
          selectedMode: false,
          textStyle: {
            fontSize: 16,
            fontFamily: "PingFangSC-Regular, PingFang SC",
            fontHeight: 400,
            color: "#FFFFFF",
          },
        },
        series: [
          {
            type: "pie",
            minAngle: 15, //最小角度
            startAngle: 100, //起始角度
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "inner",
              color: "#FFFFFF",
              fontSize: "14",
              fontWeight: "500",
              fontFamily: "PingFangSC-Medium, PingFang SC",
              formatter: function (params) {
                let value = params.value || 0;
                return value + "家\n" + params.data.proportion + "%";
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: "工厂数量",
            type: "pie",
            minAngle: 15, //最小角度
            startAngle: 100, //起始角度
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              width: 150,
              overflow: "truncate",
            },
            labelLine: {
              show: true,
            },
            select: {
              label: {
                show: true,
                rotate: true,
                borderType: "solid",
                fontSize: "20",
                fontWeight: "500",
                fontFamily: "PingFangSC-Medium, PingFang SC",
                formatter: function (params) {
                  // let label=params.name + "工厂数量"

                  return params.name + "工厂数量";
                },
              },
              labelLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255, 255, 255, 1)",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                rotate: true,
                color: "#FFFFFF",
                fontSize: "20",
                fontWeight: "500",
                fontFamily: "PingFangSC-Medium, PingFang SC",
                formatter: function (params) {
                  // let label=params.name + "工厂数量"

                  return params.name + "工厂数量";
                },
              },
              labelLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255, 255, 255, 1)",
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  created() {
    this.getMapFactorys();
    if (this.checkedOne == 0) {
      this.getAllIndustry();
    } else {
      this.initCharts2();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.checkedOne == 0) {
        // this.initCharts1();
      } else {
        this.initCharts2();
      }
    });
  },
  methods: {
    //获取工厂数据
    getMapFactorys() {
      this.imgIdentification = [];
      this.$http.get(this.$api.getFactorys + "?county&industry").then((res) => {
        if (res && res.length > 0) {
          res.map((x) => {
            x.value = x.simple_name;
            x.name = x.county;
          });
          this.imgIdentification = res;
          let sum = this.imgIdentification.length;
          this.echartData1.map((x, i) => {
            x.count = 0;
            x.count = this.imgIdentification.filter(
              (y) => y.county == x.name
            ).length;
          });
          this.echartData1.map((x, i) => {
            let dataValue = parseFloat(x.count / sum) * 100;
            x.proportion = dataValue.toFixed(1);
          });
          console.log(`this.echartData1=`, this.echartData1);
          console.log(`this.imgIdentification=`, this.imgIdentification);
        }
        this.getData();
      });
    },
    //获取所有的统计数据
    getAllIndustry() {
      this.$http.get(this.$api.getIndustry + "?county&industry").then((res) => {
        let arrObj = [];
        let arrObj1 = [];
        let arrObj2 = [];
        if (res) {
          this.allIndustry = res;
          //计算数组的总和
          let sum = Object.values(res).reduce(function (
            prev,
            cur,
            index,
            array
          ) {
            return prev + cur;
          });
          for (let key in res) {
            let dataValue = parseFloat(res[key] / sum) * 100;
            let proportion = dataValue.toFixed(1);
            arrObj.push({
              value: res[key],
              name: key,
              proportion: proportion,
              selected: false,
            });
          }
        }
        arrObj.map((x, i) => {
          let color1 = this.color2[0][0].color;
          let color2 = this.color2[0];
          if (i <= this.color2.length - 1) {
            color1 = this.color2[i][0].color;
            color2 = this.color2[i];
          }
          let style1 = JSON.parse(
            JSON.stringify(
              Object.assign(x, {
                itemStyle: {
                  borderColor: color1,
                  borderWidth: "2",
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: color2,
                    global: false,
                  },
                },
              })
            )
          );

          let style2 = JSON.parse(
            JSON.stringify(
              Object.assign(x, {
                itemStyle: {
                  borderColor: color1,
                  borderWidth: "2",
                  color: "transparent",
                },
              })
            )
          );
          arrObj1.push(style1);
          arrObj2.push(style2);
        });
        this.option2.series[0].data = arrObj1;
        this.option2.series[1].data = arrObj2;
        if (this.myChart2) {
          this.myChart2.clear();
          this.toOptions1();
        } else {
          this.initCharts1();
        }
      });
    },
    chooseOne(index) {
      this.checkedOne = index;
      this.getData();
      this.$nextTick(() => {
        if (index == 0) {
          this.getAllIndustry();
        } else {
          this.initCharts2();
        }
      });
    },
    getData(name) {
      let obj = shanghaiMap;
      this.imgShowMap = [];
      this.listArr = [];
      if (obj) {
        let arr = obj.features;
        // 循环取出 城市名称和value值
        for (var j = 0; j < arr.length; j++) {
          let isSelect = false;
          this.max = arr[0].id;
          this.min = arr[0].id;
          if (arr[j].id > this.max) {
            this.max = arr[j].id;
          }
          if (arr[j].id < this.min) {
            this.min = arr[j].id;
          }
          if (arr[j].properties?.name == name) {
            isSelect = true;
            this.mapCenter = arr[j].properties.cp;
          }
          this.listArr.push({
            name: arr[j].properties?.name,
            value: arr[j].id,
            selected: isSelect,
            label: {
              color: "#fff",
              fontSize: 12,
              fontWeight: 100,
            },
            itemStyle: {
              borderColor: "#fff",
              shadowBlur: {
                shadowColor: "#0b2065",
                shadowBlur: 0,
              }, // 图形阴影的模糊大小。
              shadowColor: "#0d58bd", // 阴影色 #233F53
              shadowOffsetX: 5, // X轴阴影
              shadowOffsetY: 5, // Y轴阴影
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#287df0", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1a4dff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          });
        }
        this.imgIdentification.map((x) => {
          let data = JSON.parse(JSON.stringify(x));
          data.value = null;
          if (name) {
            if (x.name == name) {
              data.value = x.value;
              data.name = x.simple_name;
              this.imgShowMap.push(data);
            }
          } else {
            data.name = x.simple_name;
            this.imgShowMap.push(data);
          }
        });
        console.log(`this.imgShowMap=`, this.imgShowMap);
      }
      this.$nextTick(() => {
        if (this.checkedOne == 1) {
          this.option1.series[0].select.itemStyle.areaColor = "#0b2065";
        } else {
          this.option1.series[0].select.itemStyle.areaColor = {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#0d58bd", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#00208f", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          };
        }
        if (this.myChart1) {
          this.myChart1.clear();
          this.option1.series[0].data = this.listArr;
          this.option1.series[0].markPoint.data = this.imgShowMap;
          // this.option1.series[0].center = this.mapCenter;
          // this.option1.series[0].zoom = 2;
          this.toOptionMap();
        } else {
          this.option1.series[0].center = null;
          this.option1.series[0].zoom = 1;
          this.initCharts();
        }
      });
    },
    initCharts() {
      this.myChart1 = this.$echarts.init(
        document.getElementById("province-map")
      );
      this.$echarts.registerMap("shanghai", shanghaiMap);
      this.option1.series[0].data = this.listArr;
      this.option1.series[0].markPoint.data = this.imgShowMap;
      this.toOptionMap();

      console.log(`this.listArr=`, this.listArr);

      function findIndexByCounty(county) {
        for (let i = 0; i < this.listArr.length; i++) {
          if (this.listArr[i].name === county) {
            return i;
          }
        }
        return -1; // 如果没找到匹配项，返回 -1
      }

      let oldIndex = -1;
      let oldCountyIndex = -1;
      console.log(this.myChart1.getModel().getSeriesByIndex(0).getData());
      // 在定时任务中实现 TODO 部分
      setInterval(async () => {
        await this.$nextTick(() => {
          // 以下是 TODO 部分的实现
          let seriesDataLength = this.option1.series[0].markPoint.data.length;
          let index = Math.floor(Math.random() * seriesDataLength);
          let currentItem = this.option1.series[0].markPoint.data[index];
          let countyIndex = findIndexByCounty.call(this, currentItem.county);

          // if (oldCountyIndex) {
          //   this.myChart1.dispatchAction({
          //     type: "mapUnSelect",
          //     seriesIndex: 0,
          //     dataIndex: oldCountyIndex,
          //   });
          // }

          // if (oldIndex > -1) {
          //   // 隐藏之前的 Tooltip
          //   this.myChart1.dispatchAction({
          //     type: "hideTip",
          //     seriesIndex: 0,
          //     // name: this.option1.series[0].markPoint.data[oldIndex].name,
          //     dataIndex : oldIndex,
          //   });
          // }

          // // 地图选中当前项
          // this.myChart1.dispatchAction({
          //   type: "mapSelect",
          //   seriesIndex: 0,
          //   dataIndex: countyIndex,
          // });

          // // 显示当前项的 Tooltip
          // this.myChart1.dispatchAction({
          //   type: "showTip",
          //   seriesIndex: 0,
          //   // name: currentItem.name,
          //   dataIndex : index,
          // });

          oldIndex = index;
          oldCountyIndex = countyIndex;
          console.log(`index=${index}`);
          console.log(`countyIndex=${countyIndex}`);
        });
      }, 5000);
    },
    toOptionMap() {
      let _this = this;
      this.myChart1.setOption(this.option1, true);
      this.myChart1.off("click");
      this.myChart1.on("click", (param) => {
        if (param.componentType == "markPoint") {
          _this.toDetail(param);
        } else {
          _this.initChoose(param);
        }
      });
    },
    toDetail(param) {
      const h = this.$createElement;
      if (param.data?.link && param.data?.link !== "") {
        let isurl = this.isURL(param.data.link);
        if (isurl) {
          let data = {
            isDetail: 1,
            ifreamUrl: param.data.link,
          };
          this.ifreamUrl = param.data.link;
          this.$router.push({ path: "/zngcalj", query: data });
        } else {
          this.$message({
            type: "warning",
            message: h("p", null, [
              h(
                "i",
                { style: "color: #E6A23C;font-size:25px; font-style: normal;" },
                "当前工厂暂无案例\n 敬请期待！"
              ),
            ]),
            duration: 2000,
          });
        }
      } else {
        // this.$message({
        //   type: "warning",
        //   message: h("p", null, [
        //     h(
        //       "i",
        //       { style: "color: #E6A23C;font-size:75px;font-style: normal;" },
        //       "当前工厂暂无案例 敬请期待！"
        //     ),
        //   ]),
        //   duration: 2000,
        // });
        this.initChoose(param);
      }
      //  let data={
      //     isDetail:1,
      //     ifreamUrl:'/static/01dz.html'
      //   }
      //   // this.ifreamUrl='/static/01dz.html'
      //   this.$router.push({path:'/zngcalj',query:data})
    },
    isURL(str) {
      //varreg=/[0-9a-zA-z]+.(html|htm|shtml|jsp|asp|php|com|cn|net|com.cn|org)$/;
      //必须包含.(最后面一个.前面最少有一个字符)且.后面最少有一个单词字符，最后一个字符必须为单词字符或/
      var reg = /\w+.(\w+|\w+\/)$/;
      var isurl = reg.test(str); //test(str)方法是js正确表达式内置的对象可以直接调用
      return isurl;
    },
    initChoose(param) {
      let _this = this;
      if (this.checkedOne == 1) {
        _this.getData(param.name);
        let dataIndex = null;
        _this.echartData1.map((x, i) => {
          if (x.name == param.name) {
            dataIndex = i;
          }
        });
        if (dataIndex != null) {
          _this.myChart3.dispatchAction({
            type: "downplay",
          });
          _this.myChart3.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: dataIndex,
          });
          _this.myChart3.dispatchAction({
            type: "highlight",
            seriesIndex: 1,
            dataIndex: dataIndex,
          });
        }
      }
    },
    initCharts1() {
      this.myChart2 = this.$echarts.init(
        document.getElementById("ring-echarts")
      );
      this.toOptions1();
    },
    toOptions1() {
      var _this = this;
      this.myChart2.setOption(this.option2, true);
      this.myChart2.off("click");
      this.myChart2.on("click", function (params) {
        _this.myChart2.dispatchAction({
          type: "downplay",
        });
        _this.myChart2.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: params.dataIndex,
        });
        _this.myChart2.dispatchAction({
          type: "highlight",
          seriesIndex: 1,
          dataIndex: params.dataIndex,
        });
        _this.$http
          .get(_this.$api.getFactorys + "?county&industry=" + params.name)
          .then((res) => {
            if (res && res.length > 0) {
              res.map((x) => {
                x.value = x.simple_name;
                x.name = x.county;
              });
              if (_this.myChart1) {
                _this.myChart1.clear();
              }
              _this.option1.series[0].markPoint.data = res;
              _this.toOptionMap();
            }
          });
      });
    },
    initCharts2() {
      let _this = this;
      let newArr = _this.echartData1.filter((x) => x.count != 0);
      newArr = newArr.sort((a, b) => {
        return a.count - b.count;
      });
      // newArr.forEach((x, index) => {
      //   x.value = index + 1;
      // });
      newArr = newArr.sort((a, b) => {
        return b.value - a.value;
      });
      this.myChart3 = this.$echarts.init(
        document.getElementById("pie-echarts")
      );
      let option = {
        tooltip: {
          trigger: "item",
          // formatter: "{a} <br/>{b} : {d}%",
          formatter: function (param) {
            return `区域分布 <br/> ${param.name} : ${param.data.proportion}%`;
          },
        },
        legend: {
          left: "center",
          top: "bottom",
          selectedMode: false,
          textStyle: {
            fontSize: 12,
            fontFamily: "Helvetica",
            color: "#B9B8CE",
          },
        },
        series: [
          {
            name: "区域分布",
            type: "pie",
            radius: [100, 240],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
              // color:'transparent',
              opacity: 0.8,
            },
            emphasis: {
              itemStyle: {
                borderColor: "#FFFFFF",
                borderWidth: 2,
                borderType: "solid",
              },
            },
            label: {
              show: true,
              fontSize: 13,
              fontFamily: "Helvetica",
              // color: '#1478B7',
              formatter: [
                "{a|这段文本采用样式a}",
                "{b|这段文本采用样式b}这段用默认样式{x|这段用样式x}",
              ].join("\n"),
              formatter: function (param) {
                return `{a|${param.name}（${param.data.value}家）}\n{b|${param.data.proportion}%}`;
              },
              rich: {
                a: {
                  fontSize: 13,
                  fontFamily: "Helvetica",
                  color: "#FFF",
                  width: 40,
                  align: "left",
                },
                b: {
                  fontSize: 16,
                  width: 40,
                  align: "left",
                  fontFamily: "PingFangSC-Regular, PingFang SC",
                  fontWeight: 400,
                  color: "#FFFFFF",
                  lineHeight: 35,
                },
              },
            },
            labelLine: {
              show: true,
            },
            data: newArr,
          },
          {
            name: "区域分布",
            type: "pie",
            radius: [100, 240],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 5,
              color: "transparent",
            },
            select: {
              label: {
                show: true,
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            label: {
              show: false,
              position: "inner",
              fontSize: 13,
              fontFamily: "Helvetica",
              // color: '#1478B7',

              formatter: [
                "{a|这段文本采用样式a}",
                "{b|这段文本采用样式b}这段用默认样式{x|这段用样式x}",
              ].join("\n"),
              formatter: function (param) {
                return `{a|${param.name}}\n{b|${param.data.proportion}%}`;
              },
              rich: {
                a: {
                  fontSize: 13,
                  fontFamily: "Helvetica",
                  color: "#FFFFFF",
                  width: 40,
                  align: "left",
                },
                b: {
                  fontSize: 16,
                  width: 40,
                  align: "left",
                  fontFamily: "PingFangSC-Regular, PingFang SC",
                  fontWeight: 400,
                  color: "#FFFFFF",
                  lineHeight: 35,
                },
              },
            },
            data: newArr,
          },
        ],
      };
      this.myChart3.setOption(option, true);
      this.myChart3.off("click");
      this.myChart3.on("click", function (params) {
        _this.myChart3.dispatchAction({
          type: "downplay",
        });
        _this.myChart3.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: params.dataIndex,
        });
        _this.myChart3.dispatchAction({
          type: "highlight",
          seriesIndex: 1,
          dataIndex: params.dataIndex,
        });
        if (this.myChart1) {
          this.myChart1.clear();
        }
        // let arr = JSON.parse(JSON.stringify(_this.imgIdentification));
        // _this.option1.series[0].markPoint.data = arr.slice(
        //   0,
        //   Math.ceil(Math.random() * 10)
        // );
        _this.getData(params.data.name);
      });
    },
    // chooseOne(param) {
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.factory-distribution {
  width: 1580px;
  height: 850px;
  margin-top: 143px;
  color: #42b983;
  font-size: 30px;
  position: relative;
  .map-chart {
    width: 1580px;
    height: 848px;
  }
  .right-div {
    width: 440px;
    height: 100%;
    position: absolute;
    left: 1100px;
    top: 0px;
    scale: 0.8;
    @flex_column();
    .top-nav {
      width: 100%;
      height: 50px;
      @flex_row();
      .nav-btn,
      .nav-btn1 {
        width: 168px;
        height: 50px;
        cursor: pointer;
        transform: skew(30deg);
        background: #0f5a89;
        box-shadow: 0px 0px 2px 2px #14a2ff inset;
        opacity: 0.3;
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 26px;
          transform: skew(-30deg);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .nav-btn1 {
        margin-left: 20px;
      }
      .checked-nav {
        opacity: 1;
      }
    }
    .bottom-ring {
      width: 540px;
      height: 700px;
      #ring-echarts {
        transform: scale(0.8);
        width: 700px;
        margin-left: -120px;
        height: 700px;
      }
      #pie-echarts {
        transform: scale(0.8);
        width: 700px;
        margin-left: -120px;
        height: 700px;
      }
    }
  }
}
</style>
